<template>
  <div class="container">
    <div class="content">
      <div class="container-item">
        <!-- <button class="btn">了解更多</button> -->
        <div class="buttom-item">
          <!-- <img :src="bottomkImg" alt="" /> -->
          <input type="text" class="img-text" placeholder="输入你的手机号码" />
          <div class="title">
            <span>定制方案</span>
          </div>
        </div>
        <p v-for="(item, index) in newsLis" :key="index">
          <!-- <img v-lazy="item.textImg" alt="" /> -->
          <img v-lazy="item.textImg" alt="" class="text_img" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["newsLis"],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.content {
  background: url(../../assets/images/product/more.png) no-repeat;
  background-size: cover;
  height: 311px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.3875rem;

  .container-item {
    bottom: 35%;

    .text {
      width: 100%;
      img {
        width: 5.6375rem;
        height: 1.125rem;
      }
    }
    .buttom-item {
      width: 100%;
      margin-top: -0.625rem;
      position: relative;
      .title {
        position: absolute;
        // display: inline-block;
        top: 0.05rem;
        right: 0.05rem;

        text-align: center;
        background: linear-gradient(90deg, #ffa65f, #de7150);
        box-shadow: 0px 4px 10px 0px rgba(226, 79, 51, 0.35);
        border-radius: 10px;

        span {
          display: inline-block;
          font-size: 0.2rem;
          font-family: HYYuanLongHei;
          font-weight: normal;
          color: #ffffff;
          padding: 13px 28px;
        }
      }

      // img {
      //   width: 451px;
      //   height: 51px;
      // }
      input {
        width: 100%;
        font-size: 0.2rem;
        font-family: SimHei;

        padding: 0.2rem 0.25rem;
        background: #ffffff;
        border: 1px solid #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(79, 91, 127, 0.33);
        border-radius: 10px;
        color: #999999;
        line-height: 25px;
      }
    }
    p {
      padding-top: 24px;

      img {
        width: 100%;
        // width: 5.6375rem;
        // height: 0.925rem;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .content {
    height: 300px;
  }
}
@media screen and (max-width: 1200px) {
  .content {
    height: 285px;
    .container-item .buttom-item {
      width: 90%;
      .title {
        right: 0.4375rem;
      }
      input {
        width: 90%;
      }
    }
    .container-item p {
      width: 90%;
      img {
        width: 90%;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .content {
    .container-item .buttom-item {
      width: 85%;
      .title {
        right: 0.85rem;
        top: 0.025rem;
      }
      input {
        width: 85%;
        padding: 0.225rem 0.25rem;
      }
    }
    .container-item p {
      width: 85%;
      img {
        width: 85%;
      }
    }
  }
}
</style>
