<template>
  <div class="views-news-Industry ">
    <div class="CallOut-title ">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">企业动态</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">ENTRPRISE DYNAMIC</h3>
    </div>
    <el-row class="wrapper">
      <el-col :md="12" :xs="24"  class=" wow bounceInLeft" data-wow-duration="1.5s"
        data-wow-delay="0.1s">
        <el-carousel
          :interval="5000"
          arrow="never"
          :autoplay="false"
          height="381px"
          @change="carouselChange"
        >
          <el-carousel-item
            class="Industry "
            v-for="(item, index) in List"
            :key="index"
          >
            <div class="img-left ">
              <!-- 图片 -->
              <p>{{ item.news }}</p>
              <img :src="item.img" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :md="12" :xs="24"  class=" wow bounceInRight"
        data-wow-duration="1.5s"
        data-wow-delay="0.1s">
        <!-- 表单 -->
        <ul class="ulList	">
          <li>
            <span class="text"  v-for="(item, index) in content" :key="index"  @click=clickTitle(item) id="textId">
              <div class="title">
                <span class="cricle">
                  <!-- 索引 -->
                  {{ item.id }}
                </span>
                <span class="title-text">
                  {{ item.name }}
                </span>
              </div>
              <p>{{ item.title }}</p>
              <!-- 数据-->
            </span>
          </li>
        </ul>
      </el-col>
    </el-row>
    <div class="Industry-content">
      <div
        class="Industry-bottom"
        v-for="(item, index) in dataList"
        :key="index"
        @click=clickData(item)
      >
        <div class="left-text ">
          <!-- 左侧文字 -->
          {{ item.text }}
        </div>
        <div class="right-date">
          <!-- 日期 -->
          {{ item.date }}
        </div>
      </div>
    </div>
    <div class="pagination">
      <!-- 分页 -->

      <el-pagination background layout="prev, pager, next" :total="10">
      </el-pagination>
    </div>
    <pageMore :newsLis="newsLis"></pageMore>
  </div>
</template>
<script>
import WOW from "wowjs";
import pageMore from "@/components/common/pageMore.vue";
export default {
  name: "Industry",

  props: {},
  components: {
    pageMore,
  },
  data() {
    return {
      newsLis: [
        {
          name: "news",
          textImg: require("@/assets/images/news/icon-foot.png"),
        },
      ],
      dataList: [
        {
          text: "2020年度徐汇区企业技术中心名单的通知 ",
          date: "2020-09-28",
          url:"https://www.sohu.com/a/421346526_120460748"
        },
        {
          text: "喜讯|航动科技研发中心揭牌，聚力打造高新技术平台 ",
          date: "2021-03-16",
          url:"https://mp.weixin.qq.com/s/5X5qn-bznTrB8JaUYQrK5A"
        },
        {
          text: "数智赋能开启下一个十年 - 航动科技蝉联高成长百家榜单",
          date: "2021-02-10",
          url:"https://mp.weixin.qq.com/s/V78n9FZR2mVgvf9Ys19BWA"
        },
      ],
      List: [
        {
          news: "私域流量下的服务思考",
          img: require("@/assets/images/news/img-1.png"),
        },
        {
          news: "私域流量下的服务思考",
          img: require("@/assets/images/news/img-1.png"),
        },
        {
          news: "私域流量下的服务思考",
          img: require("@/assets/images/news/img-1.png"),
        },
        {
          news: "私域流量下的服务思考",
          img: require("@/assets/images/news/img-1.png"),
        },
      ],
      content: [
        {
          id: "1",
          name: "武职学生赴上海航动武邑分公司开展工学交替活动",
          title:
            "工学交替强技能，产教融合促发展。自去年武邑职教中心和上海航动科技有限公司签订合作合同后...",
            url:'https://baijiahao.baidu.com/s?id=1694478503427103436&wfr=spider&for=pc'
        },
        {
          id: "2",
          name: "航动科技荣获“2020新时代中国经济创新企业”",
          title:
            "12月20日“2020中国经济高峰论坛暨第十八届中国经济人物年会”在北京隆重举行。上海航动科技...",
            url:'https://www.sohu.com/a/441680607_120919946?spm=smpc.author.fd-d.1.1620116276560e69XeD2'
        },
        {
          id: "3",
          name: "喜报 | CMMI 5级权威认证航动科技研发实力",
          title:
            "好消息！圣诞前夕，上海航动科技有限公司（以下简称：航动科技）收到国际电贺，正式通过全球...",
            url:'https://www.sohu.com/a/441184466_120919946?spm=smpc.author.fd-d.2.16201155458096rJ8ngd'
        },
        {
          id: "4",

          name: "大唐移动、新华三等17家企业入围联通IT类应用软件开发服务合作 ",
          title:
            "C114讯12月8日消息（林想）中国联通日前正式发布中国联通IT类应用软件开发服务合作（常态化招募...",
            url:'http://finance.sina.com.cn/tech/2020-12-08/doc-iiznezxs5841422.shtml'
        },
      ],
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {
    carouselChange(index) {
      console.log(index);
    },
    clickData(item){
        window.open(item.url, "_blank")
    },
    clickTitle(item){
      window.open(item.url, "_blank")

    
  //备用代码
      // if(index ==0){
      //    let myurl="https://xw.qq.com/cmsid/20201021A0BBDA00"
      //     let routeData = this.$router.resolve({ path: myurl });
      //     window.open(routeData.href, '_blank');
      // }else if(index ==1){
      //    let myurl="https://www.sohu.com/a/452398376_753232"
      //    let routeData = this.$router.resolve({ path: myurl });
      //     window.open(routeData.href, '_blank');
      // }
    

    }

    
  },
};
</script>
<style lang="less" scoped>
.views-news-Industry {
  padding-top: 0.9375rem;
  height: 100%;
  box-sizing: border-box;
  .CallOut-title {
    padding-bottom: 53px;
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        color: #333333;
        font-family: SimHei;
        letter-spacing: 7px;
        white-space: nowrap;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      margin-top: 14px;
      font-size: 0.2rem;
      letter-spacing: 7px;
      font-family: SimHei;
      color: #666666;
    }
  }
  .wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .Industry {
    display: flex;
    justify-content: space-evenly;

    .img-left {
      height: 100%;
      width: 100%;

      p {
        font-size: 16px;
        transform: translateY(355px);
        color: #ffffff;
        /* left: 6px; */
        position: absolute;
        width: 100%;
        background: #182743;
        height: 36px;
        line-height: 36px;
        top: -10px;
        text-align: left;
        padding-left: 20px;
        opacity: 0.75;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
  .ulList {
    display: flex;
    justify-content: space-between;
    margin-left: 35px;
    margin-top: 33px;

    li {
      text-align: left;

      align-self: flex-end;
      width: 100%;
    }
    .cricle {
      display: inline-block;
      width: 0.375rem;
      height: 0.375rem;
      background: linear-gradient(0deg, #de7150, #ffa65f);
      box-shadow: 0px 3px 6px 0px rgba(226, 79, 51, 0.35);

      border-radius: 50%;
      color: #fff;
      line-height: 0.375rem;
      text-align: center;
    }
    .title-text {
      display: inline-block;
      padding-left: 20px;
    }
    .text {
      display: block;

      border-bottom: 1px solid #dde4ef;
      padding: 11px 0;
      // padding-top: 20px;
      .title {
        font-size: 0.2rem;
        font-family: SimHei;
        color: #333333;
      }
       .title:hover{
        cursor: pointer;  
        color: #518ae0;
      }
      p {
        font-size: 0.175rem;
        font-family: SimHei;
        color: #999999;
        line-height: 22px;
        padding-left: 0.55rem;
        margin-top: 12px;
      }
    }
  }
  .Industry-content {
    padding-top: 52px;
    .Industry-bottom {
      display: flex;
      position: relative;
      width: 80%;
      margin: 0 auto;
      border-bottom: 1px solid #dde4ef;
      padding: 26px 0;

      .left-text {
        text-align: left;
        flex: 1;
        font-size: 0.225rem;
        font-family: SimHei;
        color: #333333;
      }
      .left-text:hover{
         cursor: pointer;  
        color: #518ae0;
      }
      .right-date {
        text-align: right;
        flex: 1;
        font-size: 0.2rem;
        font-family: SimHei;
        color: #999999;
      }
    }
  }

  .pagination {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
  }
}

.el-carousel {
  margin-right: 20px;
}

/deep/ .el-carousel__indicators {
  right: 20px;
}
/deep/.el-carousel__indicators--horizontal {
  left: initial;
  transform: none;
}
/deep/ .el-carousel__button {
  height: 14px;
  border-radius: 50%;
  width: 14px;
  background: #fff;
}
/deep/ .is-active .el-carousel__button {
  width: 23px;
  height: 14px;
  background: linear-gradient(90deg, #8aeaff, #32abff);
  border-radius: 7px;
}

@media screen and (max-width: 1500px) {
  .views-news-Industry {
    .ulList {
      .text {
        padding: 6px 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .views-news-Industry {
    .ulList {
      margin-left: 0 !important;
    }
    .Industry-bottom {
      width: 80% !important;
    }
  }
}

@media screen and (max-width: 750px) {
  .views-news-Industry {
    .Industry {
      .img-left {
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .views-news-Industry {
    .line-item {
      width: 0.75rem !important;
    }
    .line-item-left {
      width: 0.75rem !important;
    }
  }
}
@media screen and (max-width: 414px) {
  .views-news-Industry {
    /deep/.el-carousel__button {
      width: 11px !important;
      height: 11px !important;
    }
    /deep/ .el-carousel__indicator--horizontal {
      padding: 0px 2px !important;
    }
    /deep/ .el-carousel {
      margin-right: 0 !important;
    }
  }
}
</style>
