<template>
  <div class="views-news-Industry ">
    <div class="CallOut-title ">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">行业资讯</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">INDUSTRY INFORMATION</h3>
    </div>
    <el-row class="wrapper">
      <el-col :md="12" :xs="24"  class=" wow bounceInLeft" data-wow-duration="1.5s"
        data-wow-delay="0.1s">
        <el-carousel
          :interval="5000"
          arrow="never"
          :autoplay="false"
          height="381px"
          @change="carouselChange"
        >
          <el-carousel-item
            class="Industry "
            v-for="(item, index) in List"
            :key="index"
          >
            <div class="img-left ">
              <!-- 图片 -->
              <p>{{ item.news }}</p>
              <img :src="item.img" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :md="12" :xs="24"  class=" wow bounceInRight" data-wow-duration="1.5s"
        data-wow-delay="0.1s">
        <!-- 表单 -->
        <ul class="ulList	">
          <li>
            <span class="text" v-for="(item, index) in content" :key="index" @click=clickTitle(item)> 
              <div class="title">
                <span class="cricle">
                  <!-- 索引 -->
                  {{ item.id }}
                </span>
                <span class="title-text">
                  {{ item.name }}
                </span>
              </div>
              <p>{{ item.title }}</p>
              <!-- 数据-->
            </span>
          </li>
        </ul>
      </el-col>
    </el-row>
    <div class="Industry-content">
      <div
        class="Industry-bottom"
        v-for="(item, index) in dataList"
        :key="index"
        @click=clickData(item)
      >
        <div class="left-text ">
          <!-- 左侧文字 -->
          {{ item.text }}
        </div>
        <div class="right-date">
          <!-- 日期 -->
          {{ item.date }}
        </div>
      </div>
    </div>
    <div class="pagination">
      <!-- 分页 -->

      <el-pagination background layout="prev, pager, next" :total="10">
      </el-pagination>
    </div>
    <pageMore :newsLis="newsLis"></pageMore>
  </div>
</template>
<script>
import WOW from "wowjs";
import pageMore from "@/components/common/pageMore.vue";
export default {
  name: "Industry",

  props: {},
  components: {
    pageMore,
  },
  data() {
    return {
      newsLis: [
        {
          name: "news",
          textImg: require("@/assets/images/news/icon-foot.png"),
        },
      ],
      dataList: [
        {
          text: "2021中国呼叫中心及企业通信大会在京成功召开",
          date: "2021-04-26",
          url:"https://www.prnasia.com/story/316378-1.shtml",
        },
        {
          text: "从电商客服到12345热线，智能人机交互能否从AI产业出圈？ ",
          date: "2021-03-30",
          url:"https://www.sohu.com/a/458060401_114778",
        },
      ],
      List: [
        {
          news: "私域流量下的服务思考",
          img: require("@/assets/images/news/img-1.png"),
        },
        {
          news: "私域流量下的服务思考",
          img: require("@/assets/images/news/img-1.png"),
        },
        {
          news: "私域流量下的服务思考",
          img: require("@/assets/images/news/img-1.png"),
        },
        {
          news: "私域流量下的服务思考",
          img: require("@/assets/images/news/img-1.png"),
        },
      ],
      content: [
        {
          id: "1",
          name: "主动迎接数字化的品牌，为什么营销却更难了？",
          title:
            "增量时代的品牌营销是粗犷的。品牌广告的黄金时期，线上互联网及线下广播电视、户外媒体广告...",
            url:"https://xw.qq.com/cmsid/20201021A0BBDA00",
        },
        {
          id: "2",
          name: "私域流量与数字化时代的个性服务： 如何从单纯营销走向全面客户体验与服务",
          title:
            "疫情加速了社会与企业的数字化线上化进程，企业的管理方式，获客方式、经营方式、交易方式和...",
            url:"https://www.sohu.com/a/452398376_753232",
        },
        {
          id: "3",
          name: "统筹客户触点管理，开启商业银行精准营销的“第二发展曲线”",
          title:
            "随着科技的发展，银行陆续打造了线上线下、丰富多样的客户触点，来满足客户日常业务办理...",
            url:"https://baijiahao.baidu.com/s?id=1668436380074524446&wfr=spider&for=pc",
        },
        {
          id: "4",

          name: "12%的 90后拥有信贷负债，智能催收成解决金融风险隐患首选 ",
          title:
            "随着消费市场的扩大以及消费观念的转变，选择提前消费或分期付款的人越来越多。但与此同时，冲动...",
            url:"https://baijiahao.baidu.com/s?id=1693298316614124084&wfr=spider&for=pc",
        },
      ],
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {
    carouselChange(index) {
      console.log(index);
    },
      clickData(item){
        window.open(item.url, "_blank")
    },
    clickTitle(item){
      window.open(item.url, "_blank")
    }
  },
};
</script>
<style lang="less" scoped>
.views-news-Industry {
  padding-top: 0.9375rem;
  height: 100%;
  box-sizing: border-box;
  .CallOut-title {
    padding-bottom: 53px;
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        color: #333333;
        font-family: SimHei;
        letter-spacing: 7px;
        white-space: nowrap;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      margin-top: 14px;
      font-size: 0.2rem;
      letter-spacing: 7px;
      font-family: SimHei;
      color: #666666;
    }
  }
  .wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .Industry {
    display: flex;
    justify-content: space-evenly;

    .img-left {
      height: 100%;
      width: 100%;
      p {
        font-size: 16px;
        transform: translateY(355px);
        color: #ffffff;
        /* left: 6px; */
        position: absolute;
        width: 100%;
        background: #182743;
        height: 36px;
        line-height: 36px;
        top: -10px;
        text-align: left;
        padding-left: 20px;
        opacity: 0.75;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
  .ulList {
    display: flex;
    justify-content: space-between;
    margin-left: 35px;
    margin-top: 33px;

    li {
      text-align: left;

      align-self: flex-end;
      width: 100%;
    }
    .cricle {
      display: inline-block;
      width: 0.375rem;
      height: 0.375rem;
      background: linear-gradient(0deg, #de7150, #ffa65f);
      box-shadow: 0px 3px 6px 0px rgba(226, 79, 51, 0.35);

      border-radius: 50%;
      color: #fff;
      line-height: 0.375rem;
      text-align: center;
    }
    .title-text {
      display: inline-block;
      padding-left: 20px;
    }
    .text {
      display: block;

      border-bottom: 1px solid #dde4ef;
      padding: 11px 0;
      // padding-top: 20px;
      .title {
        font-size: 0.2rem;
        font-family: SimHei;
        color: #333333;
      }
      .title:hover{
        cursor: pointer;  
        color: #518ae0;
      }
      p {
        font-size: 0.175rem;
        font-family: SimHei;
        color: #999999;
        line-height: 22px;
        padding-left: 0.55rem;
        margin-top: 12px;
    //     overflow: hidden;
    // text-overflow: ellipsis;
    // -webkit-box-orient: vertical;
    // display: -webkit-box;
    // -webkit-line-clamp: 1;
      }
    }
  }
  .Industry-content {
    padding-top: 52px;
    .Industry-bottom {
      display: flex;
      position: relative;
      width: 80%;
      margin: 0 auto;
      border-bottom: 1px solid #dde4ef;
      padding: 26px 0;

      .left-text {
        text-align: left;
        flex: 1;
        font-size: 0.225rem;
        font-family: SimHei;
        color: #333333;
      }
      .left-text:hover{
        cursor: pointer;  
         color: #518ae0;
      }
      .right-date {
        text-align: right;
        flex: 1;
        font-size: 0.2rem;
        font-family: SimHei;
        color: #999999;
      }
    }
  }

  .pagination {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
  }
}
.el-carousel {
  margin-right: 20px;
}

/deep/ .el-carousel__indicators {
  right: 20px;
}
/deep/.el-carousel__indicators--horizontal {
  left: initial;
  transform: none;
}
/deep/ .el-carousel__button {
  height: 14px;
  border-radius: 50%;
  width: 14px;
  background: #fff;
}
/deep/ .is-active .el-carousel__button {
  width: 23px;
  height: 14px;
  background: linear-gradient(90deg, #8aeaff, #32abff);
  border-radius: 7px;
}

@media screen and (max-width: 1500px) {
  .views-news-Industry {
    .ulList {
      // margin-top: 0.9375rem;
      .text {
        padding: 6px 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .views-news-Industry {
    .ulList {
      margin-left: 0;
    }
    .Industry-bottom {
      width: 80%;
    }
  }
}

@media screen and (max-width: 750px) {
  .views-news-Industry {
    .Industry {
      .img-left {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .views-news-Industry {
    .line-item {
      width: 0.75rem;
    }
    .line-item-left {
      width: 0.75rem;
    }
  }
}
@media screen and (max-width: 414px) {
  .views-news-Industry {
    /deep/.el-carousel__button {
      width: 11px;
      height: 11px;
    }
    /deep/ .el-carousel__indicator--horizontal {
      padding: 0px 2px;
    }
    /deep/ .el-carousel {
      margin-right: 0;
    }
  }
}
</style>
