<template>
  <div class="News ">
    <BannerNews></BannerNews>
  </div>
</template>

<script>
import BannerNews from "@/components/news/BannerNews.vue";

export default {
  name: "News",
  data() {
    return {};
  },
  components: {
    BannerNews,
  },
  props: {},

  create() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped></style>
