<template>
  <div class="views-news-Recently ">
    <div class="CallOut-title ">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">近期签约</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">RECENT SIGNING</h3>
    </div>
    <el-row class="News-middle ">
      <el-col :xs="24" :sm="12" :md="24">
        <myMap></myMap>
      </el-col>
<!--      <el-col-->
<!--        id="middle"-->
<!--        class="middle-right"-->
<!--        :xs="24"-->
<!--        :sm="12"-->
<!--        :md="12"-->
<!--        :push="1"-->
<!--      >-->
<!--        <ul>-->
<!--          <li v-for="(item, index) in dataList" :key="index" class="text">-->
<!--            <span class="text-name">{{ item.name }}</span>-->
<!--            <span class="text-title">{{ item.title }}</span>-->
<!--            <div class="text-content">{{ item.content }}</div>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <div class="pagination">-->
<!--          <el-pagination layout=" prev, pager, next" :total="3">-->
<!--          </el-pagination>-->
<!--        </div>-->
<!--      </el-col>-->
    </el-row>
    <pageMore :newsLis="newsLis"></pageMore>
  </div>
</template>
<script>
import myMap from "@/components/news/map.vue";

import pageMore from "@/components/common/pageMore.vue";
import WOW from "wowjs";
export default {
  name: "Recently",
  props: {},
  components: {
    myMap,
    pageMore,
  },

  data() {
    return {
      img: require("@/assets/images/news/map.png"),
      newsLis: [
        {
          name: "news",
          textImg: require("@/assets/images/news/icon-foot.png"),
        },
      ],
      dataList: [
        {
          name: "【湖南】",
          title: "航动科技正式签约XXX有限公司",
          content: "2021年12月23日航动科技正式签约XXX软件有限公司",
        },
        {
          name: "【湖南】",
          title: "航动科技正式签约XXX有限公司",
          content: "2021年12月23日航动科技正式签约XXX软件有限公司",
        },
        {
          name: "【湖南】",
          title: "航动科技正式签约XXX有限公司",
          content: "2021年12月23日航动科技正式签约XXX软件有限公司",
        },
        {
          name: "【湖南】",
          title: "航动科技正式签约XXX有限公司",
          content: "2021年12月23日航动科技正式签约XXX软件有限公司",
        },
        {
          name: "【湖南】",
          title: "航动科技正式签约XXX有限公司",
          content: "2021年12月23日航动科技正式签约XXX软件有限公司",
        },
        {
          name: "【湖南】",
          title: "航动科技正式签约XXX有限公司",
          content: "2021年12月23日航动科技正式签约XXX软件有限公司",
        },
      ],
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.views-news-Recently {
  padding-top: 0.9375rem;
  height: 100%;
  box-sizing: border-box;
  .CallOut-title {
    padding-bottom: 74px;
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        color: #333333;
        font-family: SimHei;
        letter-spacing: 7px;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      margin-top: 14px;
      font-size: 0.2rem;
      letter-spacing: 7px;
      font-family: SimHei;
      color: #666666;
    }
  }
  .News-middle {
    // display: flex;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 1.875rem;

    // justify-content: space-around;

    .middle-right {
      background: #ffffff;
      box-shadow: 0px 4px 32px 0px rgba(18, 43, 108, 0.18);
      border-radius: 20px;
      text-align: left;
      padding: 40px 10px;
      // margin-left: 10px;
    }
    .text {
      width: 100%;
      padding-left: 20px;
      padding-top: 5px;
      padding-bottom: 5px;

      // display: inline-block;
      // border-bottom: 1px solid #dde4ef;
span:hover{
  color: #409EFF;
}
      .text-name {
        font-size: 18px;
        font-family: SimHei;
        color: #333333;
      }
      .text-title {
        font-size: 18px;
        font-family: SimHei;
        color: #333333;
      }
      .text-content {
        font-size: 14px;
        font-family: SimHei;
        color: #999999;
        padding-top: 10px;
        padding-left: 10px;
      }
      &::after {
        content: "";
        display: block;
        height: 1px;
        width: 8.55rem;
        // color: #333333;
        background: #dde4ef;
        margin: 10px 0;
        opacity: 0.6;
      }
    }
  }
}

.el-pagination {
  text-align: center;
  margin-top: 10px;
}
@media screen and (max-width: 992px) {
  .views-news-Recently {
    .News-middle {
      .text {
        padding-left: 10px;
      }
      .middle-right {
        padding: 10px 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .views-news-Recently {
    .News-middle {
      .text {
        text-align: left;
        .text-name {
          font-size: 16px;
        }
        .text-title {
          font-size: 16px;
        }
        &::after {
          width: 100%;
        }
      }
      .middle-right {
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .views-news-Recently {
    .line-item {
      width: 0.75rem !important;
    }
    .line-item-left {
      width: 0.75rem !important;
    }
    .News-middle {
      .text {
        padding-left: 5px;
      }
      .middle-right {
        padding: 15px 10px;
      }
    }
  }
}
</style>
