<template>
  <div ref="oroptionmapjsonr" id="map" :style="{ height: mapHeight }"></div>
</template>

<script>
var ltopdata = [
  {
    name: "北京",
    value: 2,
  },
  {
    name: "天津",
    value: 1,
  },
  {
    name: "上海",
    value: 2,
  },
  {
    name: "重庆",
    value: 1,
  },
  {
    name: "河北",
    value: 1,
  },
  {
    name: "河南",
    value: 1,
  },
  {
    name: "云南",
    value: 1,
  },
  {
    name: "辽宁",
    value: 1,
  },
  {
    name: "黑龙江",
    value: 1,
  },
  {
    name: "湖南",
    value: 1,
  },
];
const eCharts = require("echarts");
import china from "@/assets/data/china.json";
// import Update from "@/components/news/Update.vue";

export default {
  name: "HelloWorld",
  props: {
    middle: {
      type: String,
    },
  },
  data() {
    return {
      mapHeight: 0,
      middleWidth: 0,
      oroptionmapjsonr: {
        textStyle: {
          color: "#9cbfde",
          fontSize: 10.67,
        },
        color: ["#fff"], //手动设置每个图例的颜色
        tooltip: {
          // trigger: 'item',
          formatter: function(params) {
            for (var i = 0; i < ltopdata.length; i++) {
              if (params.name == ltopdata[i].name) {
                if (params.name !== "") {
                  return params.name + "\n" + ltopdata[i].value;
                } else {
                  return "";
                }
              }
            }
          },
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: true,
          orient: "vertical",
          x: "right",
          y: "center",
        },
        roamController: {
          show: true,
          x: "right",
          mapTypeControl: {
            china: true,
          },
        },
        series: [
          {
            name: "iphoneX",
            type: "map",
            mapType: "china",
            roam: false,
            left: "25%",
            top: "25%",
            // zoom: 1,
            center: [115.97, 29.71],
            // aspectScale:1,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                areaColor: "#fff",
                borderColor: "#41c7db ",
                borderWidth: 1,
              },
              emphasis: {
                areaColor: "#41c7db ",
              },
            },
            data: ltopdata,
          },
        ],
      },
    };
  },
  mounted() {
    var middle = document.querySelector("body").clientWidth;
    console.log(middle);

    if (middle < 768) {
      this.mapHeight = 400 + "px";
    } else if (middle < 992) {
      this.mapHeight = 536 + "px";
    } else {
      this.mapHeight = 590 + "px";
    }

    this.$nextTick(() => {
      this.echarts();
    });
  },

  methods: {
    // 图表
    echarts() {
      //1.中国地图坐标 必须的
      eCharts.registerMap("china", china);
      //2.初始化图标
      console.log(eCharts);
      var oroptionmapjsonr_charts = eCharts.init(this.$refs.oroptionmapjsonr);
      var geoCoordMap = {
        // 台湾: [121.5135, 25.0308],
        黑龙江: [127.9688, 45.368],
        湖南: [112.982279, 28.19409],
        广西: [108.479, 23.1152],
        四川: [103.9526, 30.7617],
        上海: [121.4648, 31.2891],
      };
      var data = [
        {
          name: "黑龙江",
          value: "99+",
          img: require("@/assets/images/news/icon2.png"),
        },
        {
          name: "湖南",
          value: 5,
          selected: true,
          img: require("@/assets/images/news/icon2.png"),
        },
        {
          name: "广西",
          value: 2,
          img: require("@/assets/images/news/icon2.png"),
        },
        {
          name: "四川",
          value: 2,
          img: require("@/assets/images/news/icon2.png"),
        },
        {
          name: "上海",
          value: 12,
          img: require("@/assets/images/news/icon3.png"),
        },
        { name: "南海诸岛", itemStyle: { normal: { opacity: 0 } } }, //只剩一个了
      ];
      var convertData = function(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value), //必须加两//
              img: "image://" + data[i].img,
            });
          }
        }
        return res;
      };

      let option = {
        backgroundColor: {
          //地图的背景颜色
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#fff", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#fff", // 100% 处的颜色
            },
          ],
          globalCoord: false, // 缺省为 false
        },

        tooltip: {
          trigger: "item",
          formatter: function(params) {
            if (typeof params.value[2] == "undefined") {
              return;
            } else {
              return params.name + " : " + params.value[2];
            }
          },
        },
        /*   legend: {
            orient: 'vertical',
            y: 'bottom',
            x: 'right',
             data:['pm2.5'],
            textStyle: {
                color: '#fff'
            }
        },*/
        legend: {
          orient: "vertical",
          y: "bottom",
          x: "right",
          data: ["pm2.5"],
          textStyle: {
            color: "#fff",
          },
        },
        visualMap: {
          show: false,
          min: 0,
          max: 500,
          left: "left",
          top: "bottom",
          text: ["高", "低"], // 文本，默认为数值文本
          calculable: true,
          seriesIndex: [1],
          inRange: {},
        },
        geo: {
          map: "china",
          show: true,
          // top: "center", //设置地图偏移量 出现两层地图效果
          // left: "19%",
          roam: false, //是否允许移动
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          layoutCenter: ["50%", "50%"],
          // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
          layoutSize: "100%",
          itemStyle: {
            //默认地图的颜色 也就是底部的
            normal: {
              areaColor: "#009def",
              borderColor: "#009def", //线
              shadowColor: "rgba(22,27,91,0.2)", //外发光
              shadowBlur: 20, //阴影
            },
            emphasis: {
              areaColor: "#00AAF7", //悬浮区背景
            },
          },
          regions: [
            //对地图某一快区域设置特定的样式这个设置没有生效是因为选中在底部 懂了
            //所以说我们要改变上边的地图的选中效果就改变series中的数据选中
            {
              name: "南海诸岛",
              itemStyle: {
                opacity: 0,
              },
            },
          ],
          select: {
            // 选中后的颜色
            itemStyle: {
              borderWidth: 0,
              areaColor: "#00AAF7",
              color: "#fff",
              shadowColor: "rgba(0,0,0,0.5)", //你自己配置给阴影颜色
              shadowOffsetY: "5",
            },
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            // top: "center", //设置地图偏移量 出现两层地图效果
            // left: "20%",
            layoutCenter: ["50%", "49%"],
            // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
            layoutSize: "100%",
            aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示 可以了 南海
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: "#fff",
                },
              },
            },
            roam: false, //是否允许移动
            select: {
              // 选中后的颜色
              itemStyle: {
                borderWidth: 0,
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#01a1f5", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#01a1f5", // 100% 处的颜色
                    },
                  ],
                },
                color: "#fff",
                shadowColor: "rgba(0,0,0,.5)", //你自己配置给阴影颜色
                shadowOffsetY: "5",
              },
            },
            itemStyle: {
              //最顶部的样式颜色
              normal: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    //最顶部一层的样式颜色
                    {
                      offset: 0,
                      color: "#508ee3", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#508ee3", // 100% 处的颜色
                    },
                  ],
                },
                borderColor: "#2f6cc4",
                borderWidth: 1,
              },
              emphasis: {
                //鼠标经过的时候地图的颜色
                areaColor: "#01a1f5", //悬浮区背景
              },
            },
            animation: false,
            data: data,
          },
          // {
          //   name: "Top 5",
          //   type: "scatter",
          //   coordinateSystem: "geo",
          //   symbol: "pin",
          //   symbolSize: [35, 35],
          //   label: {
          //     normal: {
          //       show: true,
          //       textStyle: {
          //         color: "#fff",
          //         fontSize: 9,
          //       },
          //       formatter(value) {
          //         return value.data.value[2];
          //       },
          //     },
          //   },
          //   itemStyle: {
          //     normal: {
          //       // color: "#1A61A6", //标志颜色
          //       shadowColor: "#02AFF3",
          //       borderColor: "#02AFF3",
          //       borderWidth: "3",
          //     },
          //   },
          //   roam: false,
          //   data: convertData(data),
          //   showEffectOn: "render",
          //   rippleEffect: {
          //     brushType: "stroke",
          //   },
          //   hoverAnimation: true,
          //   zlevel: 1,
          // },
          {
            type: "scatter", //设置为scatter
            coordinateSystem: "geo", //geo 就可以了
            itemStyle: {
              color: "#f00",
            },
            symbol: function(value, params) {
              // console.log(params.data); //可以看到你的img没有放进来
              // symbol是一个函数返回两个参数 参数是自己定义的随便什么名字用来接收参数
              return params.data.img; //这个就是获取 data属性 mapDate对象中的数据
            },
            label: {
              //标签
              normal: {
                // 绝对的像素值
                position: [10, 15],
                // 相对的百分比
                // position: ["50%", "50%"],
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 13,
                },
                formatter(value) {
                  return value.data.value[2];
                },
              },
            },
            symbolSize: [39, 70], //大小
            symbolOffset: [-10, -20], //图标和label整体偏移
            z: 9999,
            data: convertData(data),
          },
        ],
      };
      oroptionmapjsonr_charts.setOption(option, true); //表示重新绘制地图
      window.onresize = function(e) {
        var maptWidth = document.getElementsByTagName("body")[0].clientWidth;

        //在这里改
        console.log(maptWidth, "00");
        console.log(e.target.innerWidth);
        if (maptWidth < 768) {
          this.mapHeight = 400 + "px";
        } else if (maptWidth < 992) {
          this.mapHeight = 536 + "px";
        } else {
          this.mapHeight = 590 + "px";
        }
        console.log(this.mapHeight, "this.mapHeight");

        oroptionmapjsonr_charts.resize();
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#map {
  width: 100%;
}
</style>
