<template>
  <div class="views-news-Update ">
    <div class="CallOut-title">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">更新日志</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">RECENT UPDATES</h3>
    </div>

    <div class="update-content">
      <div class="update-bottom" v-for="(item, index) in dataList" :key="index">
        <div class="left-text">
          <!-- 左侧文字 -->
          {{ item.text }}
        </div>
        <div class="right-date">
          <!-- 日期 -->
          <span>----</span>
          {{ item.date }}
        </div>
      </div>
    </div>
    <pageMore :newsLis="newsLis"></pageMore>
  </div>
</template>
<script>
import WOW from "wowjs";
import pageMore from "@/components/common/pageMore.vue";
export default {
  name: "update",

  props: {},
  components: {
    pageMore,
  },
  data() {
    return {
      newsLis: [
        {
          name: "news",
          textImg: require("@/assets/images/news/icon-foot.png"),
        },
      ],
      dataList: [
        {
          text: "【升级日志】话加4.1平台更新详情",
          date: "2021-06-21 23:00",
        },
        {
          text: "【升级日志】客户微生态运营平台1.10版本更新详情 ",
          date: "2021-06-21 23:00",
        },
      ],
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.views-news-Update {
  padding-top: 0.9375rem;

  box-sizing: border-box;
  .CallOut-title {
    padding-bottom: 53px;
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        color: #333333;
        font-family: SimHei;
        letter-spacing: 7px;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      margin-top: 14px;
      font-size: 0.2rem;
      letter-spacing: 7px;
      font-family: SimHei;
      color: #666666;
    }
  }
  .update-content {
    padding-top: 52px;
    height: 7.5rem;
    .update-bottom {
      display: flex;
      position: relative;
      width: 65%;
      margin: 0 auto;
      border-bottom: 1px solid #dde4ef;
      padding: 17px 0;

      .left-text {
        text-align: left;
        flex: 1;
        font-size: 0.225rem;
        font-family: SimHei;
        color: #333333;
      }
      .right-date {
        text-align: right;
        flex: 1;
        font-size: 0.2rem;
        font-family: SimHei;
        color: #999999;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .line-item {
    width: 0.75rem !important;
  }
  .line-item-left {
    width: 0.75rem !important;
  }
}
</style>
