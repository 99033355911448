<template>
  <div class="views-news-Banner ">
    <div class="Banner-header ">
      <!-- 大图 -->

      <div class="Banner-title">
        <img v-lazy="BannerTitle" />
      </div>
      <div class="Banner-image">
        <img v-lazy="build" />
      </div>

      <!-- <el-image :src="build" class="Banner-image" lazy></el-image> -->

      <div>
        <BannerTtab @getTabClick="onClick">{{ title }}</BannerTtab>
        <!-- <div class="foote-item">
          <ul>
            <li
              :class="{ active: item.tab == active }"
              v-for="(item, index) in tabList"
              :key="index"
              @click="onTabClick(item, index)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="about-content">
      <Enterprise v-if="active == 'Enterprise'" />
      <Industry v-if="active == 'Industry'" />
      <Recently v-if="active == 'Recently'" />
      <Update v-if="active == 'Update'" />
    </div>
  </div>
</template>
<script>
import WOW from "wowjs";

import BannerTtab from "@/components/news/Banner-tab.vue";
import Enterprise from "@/components/news/Enterprise.vue";
import Industry from "@/components/news/Industry.vue";
import Recently from "@/components/news/Recently.vue";
import Update from "@/components/news/Update.vue";
export default {
  name: "Banner",
  components: {
    Enterprise,
    Industry,
    Recently,
    Update,
    BannerTtab,
  },
  props: {},
  data() {
    return {
      active: "Industry",
      title: "",
      BannerTitle: require("@/assets/images/news/title.png"),
      build: require("@/assets/images/news/banner.png"),
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {
    onClick(name) {
      console.log("111", name);
      this.active = name;
    },
  },
};
</script>
<style lang="less" scoped>
.views-news-Banner {
  // margin-top: 90px;
  .Banner-header {
    position: relative;
    .Banner-title {
      position: absolute;
      z-index: 1;
      left: 19%;
      top: 40%;
      img {
        width: 100%;
      }
    }
    .Banner-image {
      width: 100%;
      img {
      width: 1920;
        height: 490px;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
.views-news-Banner {
    .Banner-header {
      .Banner-image {
        img {
       width: 1600px;
          height: 450px;
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .views-news-Banner {
    .Banner-header {
      .Banner-image {
        img {
            width: 1500px;
          height: 400px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .views-news-Banner {
    .Banner-header {
      .Banner-image {
        img {
           width: 1200px;
            height: 355px;
          // height: 400px;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .views-news-Banner {
    .Banner-header {
      .Banner-image {
        img {
           width:992px;
            height: 315px;
          // height: 330px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .views-news-Banner {
    .Banner-header {
      .Banner-image {
        img {
          width: 100%;
          // height: 300px;
          height: 255px;
        }
      }
    }
  }
}
</style>
